import { LOAN_FIELDS } from '@consts/debt/loan'
import { NEW_DEBTS_FIELD_NAMES } from '@consts/debt/newDebt'
import { StringDictionary } from 'types/common'

const ALREADY_EXISTS_VALUE = 'errorMessage.already_exists_value'
const AMOUNT_NOT_ALLOWED_FOR_SELECTED_PLAN = 'errorMessage.amount_not_allowed_for_selected_plan'
const CLIENT_ACTION_NOT_ALLOWED = 'errorMessage.client_action_not_allowed'
const CREATE_SIMULATION_DRAFT_NOT_ALLOWED = 'errorMessage.create_simulation_draft'
const DEFAULT_ERROR = 'errorMessage.default'
const DEFAULT_ERROR_INVALID_DATA = 'errorMessage.invalid_data'
const DESEREALIZED_DATA = 'errorMessage.default'
const DOWNLOAD_FILE_ERROR = 'errorMessage.download_file_error'
const DOWNLOAD_FILE_ZIP_ERROR = 'errorMessage.download_file_zip_error'
const EMAIL_NOTIFICATION_APPRAISER = 'errorMessage.email_notification_appraiser'
const ENTITY_VERSION_MISMATCH = 'errorMessage.entity_version_mismatch'
const FILE_MAX_SIZE = 'errorMessage.file_max_size'
const FILE_NOT_ALLOWED = 'errorMessage.file_not_allowed'
const FILE_TYPE = 'errorMessage.file_type'
const FORBBIDEN_ERROR = 'errorMessage.forbidden'
const FORM_VALIDATION_ERROR = 'errorMessage.form_validation_error'
const MAX_CHARACTERS = 'errorMessage.lt_256'
const NOT_FOUND_RESOURCE = 'errorMessage.not_found_resource'
const OPERATION_NOT_ALLOWED = 'errorMessage.operation_not_allowed'
const PERSON_ID_INVALID = 'errorMessage.person_id_invalid'
const SET_APPLICANT = 'errorMessage.set_applicant'
const UNAUTHORIZED = 'errorMessage.unauthorized_sessionExpired'
const UPLOAD_FILE_ERROR = 'errorMessage.upload_file_error'

export const ERROR_BY_CODE: StringDictionary = {
  FORBIDDEN: FORBBIDEN_ERROR
}

export const ERROR_MESSAGES: { [key: string]: string } = {
  ALREADY_EXISTS_VALUE,
  AMOUNT_NOT_ALLOWED_FOR_SELECTED_PLAN,
  CLIENT_ACTION_NOT_ALLOWED,
  CREATE_SIMULATION_DRAFT_NOT_ALLOWED,
  DEFAULT_ERROR_INVALID_DATA,
  DEFAULT_ERROR,
  DESEREALIZED_DATA,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE_ZIP_ERROR,
  EMAIL_NOTIFICATION_APPRAISER,
  ENTITY_VERSION_MISMATCH,
  FILE_MAX_SIZE,
  FILE_NOT_ALLOWED,
  FILE_TYPE,
  FORBBIDEN_ERROR,
  FORM_VALIDATION_ERROR,
  MAX_CHARACTERS,
  NOT_FOUND_RESOURCE,
  OPERATION_NOT_ALLOWED,
  PERSON_ID_INVALID,
  SET_APPLICANT,
  UNAUTHORIZED,
  UPLOAD_FILE_ERROR
} as const

const DEFAULT_SUCCESS = 'successMessage.default'
const CLIPBLOARD_SUCCESS = 'successMessage.clipboard'

export const SUCCESS_MESSAGES: { [key: string]: string } = {
  DEFAULT_SUCCESS,
  CLIPBLOARD_SUCCESS
} as const

export const NEW_DEBT_EMPTY_MESSAGES = {
  [NEW_DEBTS_FIELD_NAMES.total]: '',
  [NEW_DEBTS_FIELD_NAMES.delinquency30to89]: '',
  [NEW_DEBTS_FIELD_NAMES.delinquencyOver90]: ''
}
export const LOAN_EMPTY_MESSAGES = {
  [LOAN_FIELDS.balance]: '',
  [LOAN_FIELDS.monthly_payment]: '',
  [LOAN_FIELDS.total_number_installments]: '',
  [LOAN_FIELDS.number_installments_paid]: '',
  [LOAN_FIELDS.type]: ''
}
